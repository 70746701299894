import { BrowserCacheLocation } from "@azure/msal-browser";
import { environment } from "../../environments/environment";

export const authConfig = {
  auth: {
    clientId: environment.msalConfig.auth.clientId,
    authority: environment.msalConfig.auth.authority,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
};

export const authScopes: string[] = [
  ...environment.apiConfig.scopes,
  'User.Read'
];

export const roles = {
  Admin: "Administrator",
  BasicUser: "StandardUser",
  Contractor: "Contractor",
  ReadOnly: "ReadOnly"
}